import React from 'react';
import Tile from './Tile';
import CarouselImg from '../media/Tiles/CarouselImg.svg';
import CaseStudyImg from '../media/Tiles/CaseStudyImg.svg';
import FactsImg from '../media/Tiles/FactsImg.svg';
import HeadlinesIMG from '../media/Tiles/HeadlinesIMG.svg';
import MythBusterImg from '../media/Tiles/MythBusterImg.svg';
import PromotionalImg from '../media/Tiles/PromotionalImg.svg';
import QuotesImg from '../media/Tiles/QuotesImg.svg';
import Stats from '../media/Tiles/Stats.svg';
import VideoImg from '../media/Tiles/VideoImg.svg';
function Tiles() {
    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
      const props = getStoredData();
  const promptPostStructure = " Your response should be strictly in HTML to be used inside the body tag, and don't use any classes, use <ol style='list-style: auto;'> list to separate all the of them, use heading tag h2 for each content heading and paragraph tag p for actual content in the li tag. Use no images please and don't include an HTML starter or mention anything about how the content was generated."
      // console.log(props)
    const content = {
        tile1: {
            color:"#369FFF",
            heading: "Video Scripts",
            content: "Create engaging video scripts for your content.",
            button: "Write My Script",
            prompt: `Create 10 unique video scripts tailored for ${props.brandNameNiche}. Each video should target ${props.targetAudience}, reflect ${props.keyBrandValuesUSP}, and maintain the brand’s tone (${props.toneVoiceContent}). Video content should be highly engaging, insightful, and relevant to your audience’s needs. Structure the scripts as follows:
                      1. Intro Video: Introduce your brand, the niche it operates in (${props.brandNameNiche}), and your target audience (${props.targetAudience}). Explain how your brand solves their pain points while showcasing your key values (${props.keyBrandValuesUSP}).
                      2. Educational Video: Dive deep into one of ${props.mainTeachingTopics}. Explain key concepts in a way that directly addresses ${props.targetAudience}'s challenges and frustrations. Reference past content that worked well (drawing from ${props.topPerformingPosts}) to demonstrate your expertise.
                      3. Product/Service Showcase Video: Highlight a key product or service, explaining how it helps solve the audience's pain points. Use success stories or social proof to strengthen your case. Keep the tone engaging and reflect the voice of your brand.
                      4. Behind-the-Scenes Video: Take the audience behind the curtain of your brand, showing how products are made, what inspires the brand, or any personal insights that align with ${props.keyBrandValuesUSP}.
                      5. Testimonial Video: Showcase a customer story that highlights how your brand helped them overcome key challenges. Personalize it by tying it back to the frustrations (${props.targetAudience}) face.
                      6. Tips/How-To Video: Offer practical advice or steps related to ${props.mainTeachingTopics}. Provide clear, actionable steps while keeping the script short, following the desired video length (${props.contentLength}).
                      7. Seasonal/Event-Based Video: Create a video around an upcoming event or holiday, tying in how your brand stays relevant during these times.
                      8. FAQ Video: Address common questions or misconceptions about your brand, products, or niche. Use this as an opportunity to directly engage with the audience and encourage interaction.
                      9. Storytelling Video: Tell a story about your brand, starting with its inception, why it was created, and how it aligns with ${props.keyBrandValuesUSP}. This personal approach helps humanize the brand.
                      10. Re-engagement Video: Script a video aimed at re-engaging an inactive audience. Remind them why they subscribed to your content initially and provide updates or offers that encourage them to reconnect.
                      Each video script should reflect the tone (${props.toneVoiceContent}) and include a strong call to action.`+promptPostStructure,
            image: `${VideoImg}`,
        },
        tile2: {
            color:"#FF993A",
            heading: "Carousel Posts",
            content: "Design captivating carousel posts for social media.",
            button: "Create My Post",
            prompt: `Create 10 carousel post ideas for ${props.brandNameNiche}, focusing on ${props.targetAudience} and posting on ${props.socialMediaPlatforms}. The content should align with ${props.keyBrandValuesUSP} and follow the tone ${props.toneVoiceContent}. Use inspiration from ${props.topPerformingPosts} where applicable.
                    1. How-To Guide: Teach one of ${props.mainTeachingTopics}, breaking it down into easy-to-follow steps in each slide.
                    2. Product Showcase: Introduce one of your key products or services, highlighting its benefits for ${props.targetAudience} across several slides.
                    3. Before & After: Show the impact of your product/service with a before-and-after comparison relevant to your audience's pain points.
                    4. Customer Testimonial: Share a testimonial from a satisfied customer, with each slide focusing on different parts of their story.
                    5. Success Story: Tell a success story where each slide walks through the journey of how your brand helped someone from your ${props.targetAudience}.
                    6. Tips & Tricks: Share quick actionable tips related to ${props.mainTeachingTopics}, with one tip per slide.
                    7. FAQ Carousel: Answer common questions about your product/service or industry, with one question per slide.
                    8. Seasonal Special: Create a seasonal offer related to an upcoming holiday or event, with a call to action on the last slide.
                    9. Behind-the-Scenes: Showcase how one of your products or services is created or the inner workings of your team.
                    10. Comparison Carousel: Compare your product or service to a common alternative, showing why yours is the better choice for ${props.targetAudience}.`+promptPostStructure,
            image: `${CarouselImg}`,
        },
        tile3: {
            color:"#8AC53E",
            heading: "Stats/Figure Posts",
            content: "Share compelling stats and figures with ease.",
            button: "Generate My Post",
            prompt: `Create 10 posts centered around key statistics and figures for ${props.brandNameNiche}, tailored to ${props.targetAudience} on ${props.socialMediaPlatforms}. Ensure the stats are impactful and support ${props.keyBrandValuesUSP}, following the tone ${props.toneVoiceContent}.
                    1. Industry Growth Stats: Highlight an impressive stat about growth in your industry that supports why ${props.targetAudience} should invest in your services.
                    2. Customer Success Numbers: Showcase how many customers or clients you’ve helped and how that translates into meaningful results.
                    3. Before-and-After Figures: Use stats to demonstrate the before-and-after impact your product or service has on users.
                    4. Cost Savings Stat: Share a statistic about how much time or money your product/service saves for ${props.targetAudience}.
                    5. Time-Saving Data: Highlight how your solution cuts down time on common tasks for ${props.targetAudience}.
                    6. Success Rate: Display a compelling success rate or satisfaction percentage related to your services.
                    7. Market Insights: Share a relevant market or industry insight that positions ${props.brandNameNiche} as a thought leader.
                    8. Customer Retention Stat: Show off a customer retention rate or repeat customer percentage to build trust.
                    9. Performance Metrics: Display internal performance metrics that show how effective your offerings are.
                    10. Survey Results: Share survey or poll results that relate to your audience’s pain points and how your brand addresses them.`+promptPostStructure,
            image: `${Stats}`,
        },
        tile4: {
            color:"#C03EC5",
            heading: "Viral Headlines",
            content: "Craft attention-grabbing viral headlines effortlessly.",
            button: "Get My Headline",
            prompt: `Create 10 viral headline ideas that are attention-grabbing and designed to engage ${props.targetAudience} on ${props.socialMediaPlatforms}. The headlines should reflect ${props.keyBrandValuesUSP} and follow the tone ${props.toneVoiceContent}.
                    1. "How to Transform Your ${props.targetAudience} Strategy in Just 30 Days with ${props.brandNameNiche}!"
                    2. "The #1 Secret to Doubling Your ${props.targetAudience}'s Success in [Niche]!"
                    3. "Unlock the Hidden Power of ${props.brandNameNiche} for Unstoppable Growth!"
                    4. "You Won't Believe What Happened When ${props.targetAudience} Tried ${props.brandNameNiche}!"
                    5. "5 Mind-Blowing Ways ${props.brandNameNiche} Can Revolutionize ${props.targetAudience}'s Business!"
                    6. "Warning: Are You Making These Costly Mistakes in ${props.mainTeachingTopics}?"
                    7. "The Ultimate Guide to ${props.mainTeachingTopics} — Learn from the Best in ${props.brandNameNiche}!"
                    8. "Why ${props.targetAudience} Can’t Afford to Ignore ${props.brandNameNiche} Any Longer!"
                    9. "Discover the Exact Strategy That Helped ${props.targetAudience} 10x Their Results with ${props.brandNameNiche}!"
                    10. "Unlock the Full Potential of ${props.targetAudience} with These Insider Tips from ${props.brandNameNiche}!"`+promptPostStructure,
            image: `${HeadlinesIMG}`,
        },
        tile5: {
            color:"#FF363A",
            heading: "Quotes/Motivation",
            content: "Inspire your audience with motivational quotes.",
            button: "Find My Quote",
            prompt: `Create 10 diverse quotation posts that reflect ${props.keyBrandValuesUSP} and appeal to ${props.targetAudience}. The posts should include impactful messages or quotes that resonate with your audience’s challenges and aspirations. Occasionally, pull from ${props.topPerformingPosts} for inspiration where successful quotes can be reused effectively.
                    1. Inspirational Quote Post: Share a motivational quote that aligns with your brand values (${props.keyBrandValuesUSP}).
                    2. Educational Quote Post: Provide a teaching or tip in the form of a short quote related to ${props.mainTeachingTopics}.
                    3. Testimonial Quote: Highlight a customer's words as a testimonial, showcasing how your brand has made a difference.
                    4. Success Story Quote: Provide a quote from one of your top-performing success stories to inspire your audience.
                    5. Industry Insight Quote: Offer a quote or thought-leadership statement related to your brand’s niche (${props.brandNameNiche}).
                    6. Motivational Quote: Share an inspiring message that motivates your audience to take action, reflecting the tone (${props.toneVoiceContent}).
                    7. Brand Promise Quote: Share a quote that summarizes your brand’s mission or promise, tying it back to ${props.keyBrandValuesUSP}.
                    8. Holiday/Event Quote: Share a quote or message that’s tied to an upcoming holiday or event.
                    9. Educational Statistic Quote: Offer a statistic or fact, followed by a quote that connects to ${props.mainTeachingTopics} or niche.
                    10. Call to Action Quote: End with a post that offers a strong, action-based quote, encouraging your audience to engage with the brand or product.
                    Ensure each post fits the ${props.toneVoiceContent} tone and is formatted for ${props.socialMediaPlatforms}.`+promptPostStructure,
            image: `${QuotesImg}`,
        },
        tile6: {
            color:"#FF3AB0",
            heading: "Myth Buster",
            content: "Debunk common myths with simple facts.",
            button: "Bust My Myth",
            prompt: `Create 10 myth-busting posts for ${props.brandNameNiche} that will help clarify misconceptions for ${props.targetAudience}. These should be posted on ${props.socialMediaPlatforms} and reflect the brand’s tone (${props.toneVoiceContent}) and values (${props.keyBrandValuesUSP}).
                    1. Myth: "You Need a Huge Budget to Be Successful in ${props.mainTeachingTopics}"
                      Fact: "With the right strategy, anyone can succeed on a reasonable budget!"
                    2. Myth: "Only Big Brands Can Benefit from ${props.mainTeachingTopics}"
                      Fact: "${props.brandNameNiche} helps businesses of all sizes thrive!"
                    3. Myth: "You Need to Be an Expert to Master ${props.mainTeachingTopics}"
                      Fact: "Even beginners can see huge success with ${props.brandNameNiche}'s guidance."
                    4. Myth: "It Takes Years to See Results in ${props.mainTeachingTopics}"
                      Fact: "With ${props.brandNameNiche}, results come quicker than you think!"
                    5. Myth: "${props.mainTeachingTopics} is Too Complicated for Small Business Owners"
                      Fact: "${props.brandNameNiche} makes it simple and approachable for everyone."
                    6. Myth: "Success in ${props.mainTeachingTopics} is Pure Luck"
                      Fact: "Success is about strategy and consistency, not luck."
                    7. Myth: "You Need to Be Constantly Online to Succeed in ${props.socialMediaPlatforms}"
                      Fact: "With the right tools, you can grow without being online 24/7!"
                    8. Myth: "Content Creation is Only for Creatives"
                      Fact: "With the help of ${props.brandNameNiche}, anyone can create compelling content."
                    9. Myth: "You Have to Follow Trends to Succeed"
                      Fact: "Sticking to your brand values (${props.keyBrandValuesUSP}) is more important!"
                    10. Myth: "Paid Ads Are the Only Way to Grow"
                      Fact: "Organic growth through quality content and engagement can be just as powerful."`+promptPostStructure,
            image: `${MythBusterImg}`,
        },
        tile7: {
            color:"#30D8B7",
            heading: "Facts/Education",
            content: "Deliver educational facts and insightful content.",
            button: "Share My Fact",
            prompt: `Create 10 educational posts focused on delivering key facts or insights about ${props.brandNameNiche}, designed to inform ${props.targetAudience} on ${props.socialMediaPlatforms}. These posts should reflect ${props.keyBrandValuesUSP} and follow the tone ${props.toneVoiceContent}.
                    1. Did You Know?: Share a little-known fact about ${props.mainTeachingTopics} that will surprise your audience.
                    2. Industry History: Educate ${props.targetAudience} about the history of ${props.brandNameNiche} and how it’s evolved over time.
                    3. Common Mistakes: List 5 common mistakes ${props.targetAudience} makes in ${props.mainTeachingTopics}, with tips on how to avoid them.
                    4. Step-by-Step Process: Provide a simplified step-by-step guide on how to implement one of ${props.mainTeachingTopics}.
                    5. Essential Tools: Share 3-5 must-have tools that ${props.targetAudience} can use to succeed in ${props.mainTeachingTopics}.
                    6. How It Works: Educate your audience on the mechanics behind your service/product, breaking down the key processes.
                    7. Fact vs. Fiction: Compare a popular myth vs. the reality in your industry, providing clarity for ${props.targetAudience}.
                    8. Why It Matters: Explain why one of ${props.mainTeachingTopics} is so important for ${props.targetAudience} and how it can help solve their pain points.
                    9. Quick Tips: Offer 3-5 quick tips to help ${props.targetAudience} master ${props.mainTeachingTopics} faster.
                    10. Success Formula: Share the formula for success in ${props.mainTeachingTopics}, based on ${props.keyBrandValuesUSP}.`+promptPostStructure,
            image: `${FactsImg}`,
        },
        tile8: {
            color:"#960507",
            heading: "Promotional",
            content: "Create impactful promotional content for your brand.",
            button: "Create My Promo",
            prompt: `Create 10 promotional posts that effectively advertise ${props.brandNameNiche} on ${props.socialMediaPlatforms}, appealing directly to ${props.targetAudience}. The promotions should highlight ${props.keyBrandValuesUSP}, following the tone ${props.toneVoiceContent}.
                    1. Exclusive Offer: Announce a limited-time offer, encouraging ${props.targetAudience} to take action now to benefit from ${props.brandNameNiche}.
                    2. Discount Announcement: Promote a special discount for a specific product or service, explaining how it solves a key frustration for ${props.targetAudience}.
                    3. New Product Launch: Excite your audience about the launch of a new product or service, focusing on how it will benefit them.
                    4. Free Trial or Demo: Offer a free trial or demo of one of your products/services to give ${props.targetAudience} a risk-free way to experience ${props.brandNameNiche}.
                    5. Referral Program: Promote your referral program, highlighting how ${props.targetAudience} can benefit from sharing your brand with others.
                    6. Flash Sale: Create urgency around a flash sale for your top-selling product, encouraging immediate action.
                    7. Bundle Deal: Promote a bundle offer where multiple products/services are available at a discounted rate for a limited time.
                    8. Seasonal Offer: Run a seasonal promotion tied to an upcoming holiday or event, encouraging your audience to take advantage before the season ends.
                    9. Loyalty Rewards: Announce a loyalty program or rewards system for your most dedicated customers, motivating repeat business.
                    10. Early Access: Offer early access to a product or service for loyal customers, framing it as an exclusive perk for your audience.`+promptPostStructure,
            image: `${PromotionalImg}`,
        },
        tile9: {
            color:"#C5913E",
            heading: "Case Study",
            content: "Showcase success stories and testimonials easily.",
            button: "Write My Case Study",
            prompt: `Create 10 case study posts that showcase the success of ${props.brandNameNiche}, focusing on results you've achieved for ${props.targetAudience}. These posts should align with ${props.keyBrandValuesUSP} and be shared on ${props.socialMediaPlatforms}, using ${props.toneVoiceContent}.
                    1. Customer Success Story: Share a detailed story of how one of your clients solved a major problem with the help of ${props.brandNameNiche}.
                    2. Before & After Case Study: Highlight a transformation where you helped a client improve from a struggling state to a successful outcome.
                    3. Revenue Growth Example: Showcase a case study where a customer’s revenue increased significantly after implementing ${props.mainTeachingTopics}.
                    4. Efficiency Case Study: Present an example of how your product/service saved a customer time, focusing on specific numbers or percentages.
                    5. Client Testimonials Case Study: Combine customer quotes with real-world data to demonstrate how ${props.brandNameNiche} impacted their business.
                    6. Cost Reduction Success: Highlight a case study where a client was able to cut costs or increase profitability with the help of your services.
                    7. Niche-Specific Case Study: Share a case study that relates directly to a niche within your target audience, making it more relatable.
                    8. Step-by-Step Breakdown: Walk your audience through a detailed breakdown of how you achieved results for a client in ${props.mainTeachingTopics}.
                    9. Long-Term Results Case Study: Focus on a client who has seen sustained, long-term benefits from working with ${props.brandNameNiche}.
                    10. Collaboration Success: Highlight a collaborative effort between your team and a client, showing how teamwork led to outstanding results.`+promptPostStructure,
            image: `${CaseStudyImg}`,
        },

    }
  return (
    <div className='flex gap-5 flex-wrap justify-center'>
        {
            Object.keys(content).map((key, index) => {
              const tile = content[key];
              return (
                <Tile
                  key={index}
                  color={tile.color}
                  heading={tile.heading}
                  content={tile.content}
                  button={tile.button}
                  prompt={tile.prompt}
                  image={tile.image}
                />
              );
            })
        }
    </div>
  )
}

export default Tiles
