// import { useRef, useState } from 'react';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { useRef, useState, useEffect } from 'react';
import Loading from "./loading"
import { useNavigate } from 'react-router-dom';
const OpenAIPdfGenerator = (props) => {
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  const [loading, setLoading] = useState(false);
  const [loadingExtended, setloadingExtended] = useState(false);
  const [textAssets, setTextAssets] = useState([]);
  const [error, setError] = useState(null);
  const [generated, setGenerated] = useState(null);
  const contentRef = useRef(); // Reference for PDF content
  const hasCalledAPI = useRef(false); // Prevent double API calls
  // Function to generate text assets using OpenAI
  const generateTextAssets = async () => {
    if (hasCalledAPI.current) return; // Skip if API has already been called
    setLoading(true);
    setError(null);
    hasCalledAPI.current = true; // Mark API as called
    
    try {
      // Making the API call for all prompts in prompts.promptsContent
      const request = axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: [{ role: 'system', content: props.prompt }],
          max_tokens: 2000,
          n: 1,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
  
      // Wait for the single API request to finish
      const response = await request;
  
      // Extract the generated text from the response
      const generatedText = response.data.choices[0].message.content.trim();
  
      // Update state with the generated text
      setTextAssets([generatedText]);
  
    } catch (err) {
      setError('Error generating text assets.');
      console.error(err);
    } finally {
      setLoading(false);
      setGenerated(true);
    }
  };
  

  // Function to create the PDF document
  const generatePDF = () => {
    const element = contentRef.current;

    const options = {
      margin: 1,
      filename: 'text-assets.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    // Convert the HTML element to PDF
    html2pdf()
      .from(element)
      .set(options)
      .save();
  };
  const generateMoreContent = async () => {
    setloadingExtended(true);
    setError(null);
  
    try {
  
      // Call OpenAI API with the selected dynamic prompt
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: [{ role: 'system', content: props.prompt }],
          max_tokens: 2000,
          n: 1,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
  
      // Extract the generated text from the response
      const generatedContent = response.data.choices[0].message.content.trim();
  
      // Update the content for the clicked section
      setTextAssets((prevAssets) => {
        var updatedAssets = [...prevAssets];
        updatedAssets += '<h1 class="text-3xl font-bold text-center my-6 text-[#369FFF]">Extended Content</h1>' + generatedContent; // Update the specific section
        return updatedAssets;
      });
  
    } catch (err) {
      setError('Error generating more content.');
      console.error(err);
    } finally {
      setloadingExtended(false);
    }
  };
  useEffect(() => {
    if (props.submit && !loading && !generated) {
      generateTextAssets();
    }
  }, [props.submit, loading, generated]);
  return (
    <div className="p-8 max-w-2xl mx-auto">

      {error && <div className="text-red-500">{error}</div>}

      {/* Hidden content for PDF generation */}
      <div className='hidden'>
        <div ref={contentRef}>
            {/* {textAssets.map((text, index) => ( */}
                <>
                    <h1 className="text-3xl font-bold text-center my-6 text-black">{props.heading}</h1>
                    <span className='text-black' dangerouslySetInnerHTML={{ __html: textAssets }}></span>
                </>
            {/* ))} */}
        </div>
    </div>

      {textAssets.length > 0 ?
        <>
          <h1 className="text-3xl font-bold text-center my-6 text-[#369FFF]">{props.heading}</h1>
          <span dangerouslySetInnerHTML={{ __html: textAssets }}></span>
          <div className='flex gap-3 mt-10'>
            <div className='w-[50%]'>
              <button onClick={() => navigate(-1)} className={`h-full w-full px-4 py-2 bg-[#369FFF] text-white rounded hover:bg-opacity-90 font-bold `}>{`< Generate Other Content`}</button>
            </div>
            <div className='w-[50%]'>
              <button onClick={() => generateMoreContent()} className={`h-full w-full px-4 py-2 bg-[#FF993A] text-white rounded hover:bg-opacity-90 font-bold `} disabled={loadingExtended}>{loadingExtended ? 'Loading extended data...' : `Generate More ${props.heading} >`}</button>
            </div>
          </div>
          <button onClick={generatePDF} className="px-4 py-2 bg-[#8AC53E] text-white rounded hover:bg-opacity-90 d-block w-[100%] mt-5 font-bold">DOWNLOAD PDF</button>
        </>
        :
          <Loading apiCallDuration={30} />
      }
    </div>
  );
};

export default OpenAIPdfGenerator;
